@tailwind base;
@tailwind components;
@tailwind utilities;
@import "antd/dist/reset.css";
@import "typeface-inter";

:root {
  font-family: "Inter", sans-serif;
  font-feature-settings: "liga" 1, "calt" 1; /* fix for Chrome */
}

@supports (font-variation-settings: normal) {
  :root {
    font-family: "InterVariable", sans-serif;
  }
}

@media screen {
  .print-only {
    display: none;
  }
}

@media print {
  .print-only {
    display: block; /* Or 'flex' based on your layout */
  }
  .ssprint {
    background-color: white; /* Setting white background for print */
	width: 98%; /* Setting white background for print */
	border-width: 4px;
	margin-top: 1rem/* 16px */;
	padding-top: 1rem/* 16px */;
    padding-bottom: 2rem/* 32px */;
	padding-left: 2.75rem/* 32px */;
    padding-right: 2.75rem/* 32px */;
  }
}

body {
  margin: 0;
  padding: 0;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-overflow-scrolling: touch;
}

h1 {
  margin: 0;
  padding: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.card {
  @apply flex-col text-center mb-1 bg-gray-300 rounded-full w-[70%] m-auto;
}

.playcard {
  @apply flex snap-mandatory snap-center text-center flex-grow first:ml-[30%] last:mr-[30%] mx-3 py-32 min-w-[64%] md:min-w-[40%] bg-gray-300;
}

/* Banner Bar CSS */

.banner {
  @apply text-white;
}

/* LEFT Navigation Bar CSS */

.LeftNav-Container {
  @apply fixed sm:rounded-xl z-30 top-0 left-0 text-center bg-gray-900 text-green-400 h-screen w-1/6 mb-6 border-4 border-black;
}

.LeftNav-link {
  @apply relative transition-all text-center mt-8 p-2 h-10 border-black shadow-sm shadow-gray-800 hover:bg-gray-700 border hover:rounded-xl;
}

.LeftNav-tooltip {
  @apply absolute transition-all scale-0 w-auto min-w-max p-2 left-[105%] top-0 font-bold text-sm bg-slate-800 border-black shadow-sm shadow-gray-800 rounded-xl;
}

.LeftNav-button {
  @apply fixed transition-all h-12 pt-2 sm:pt-1 sm:h-9 top-0 left-0 sm:left-[-1rem] z-50 bg-gray-900 text-green-400 text-center w-[10%] mb-6 border-2 p-1 border-black hover:bg-black hover:rounded-xl;
}

/* RIGHT Navigation Bar CSS */
.RightNav-Container {
  @apply fixed rounded-xl z-30 top-0 right-0 text-center bg-gray-900 text-cyan-400 h-screen w-1/6 mb-6 border-4 border-black;
}

.sob-container {
  @apply flex-col first:pt-16 pb-96 overflow-y-scroll h-screen scrollbar-hide;
}

.RightNav-link-big {
  @apply relative flex-col overflow-visible transition-all text-center mt-4 p-2 border-black shadow-sm shadow-gray-800 border hover:bg-gray-800 hover:rounded-xl;
}

.RightNav-link-small {
  @apply relative flex-col transition-all text-center mt-4 p-2 border-black shadow-sm shadow-gray-800 border-2 hover:bg-gray-700 hover:rounded-xl;
}

.RightNav-link-player {
  @apply flex-col overflow-visible justify-center items-center transition-all text-center mt-4 p-2 border-black shadow-sm shadow-gray-800 border-2 hover:bg-gray-800 hover:rounded-xl;
}

.RightNav-tooltip {
  @apply z-30 absolute block right-8 scale-0 overflow-visible transition-all p-2 font-bold text-sm bg-slate-800 border-black shadow-sm shadow-gray-800 rounded-xl;
}

.RightNav-button {
  @apply fixed drop-shadow-2xl transition-all top-0 right-0 z-50 bg-gray-900 text-cyan-400 text-center h-9 w-1/6 mb-6 mt-0 border-4 border-gray-500 hover:bg-black rounded-xl hover:rounded-none;
}

/* TOP Navigation Bar CSS */
.TopNav-Container {
  @apply fixed pt-3 flex z-40 top-9 sm:top-6 left-0 bg-gray-900 text-green-400 w-screen sm:rounded-xl shadow-black shadow-md;
}

.TopNav-link {
  @apply relative left-0 transition-all text-center mx-auto my-1 p-2 w-20 border-black shadow-sm shadow-gray-900 hover:bg-gray-700 border hover:rounded-xl;
}

.TopNav-tooltip {
  @apply absolute transition-all z-50 scale-0 w-auto min-w-max p-2 top-12 left-1 font-bold text-sm bg-slate-800 border-black shadow-sm shadow-gray-800 rounded-xl;
}

.TopNav-button {
  @apply fixed transition-all shadow-md bottom-0 left-0 right-0 mx-auto z-50 bg-gray-900 text-rose-500 h-6 w-1/6 border-4 border-gray-600 hover:bg-black rounded-xl hover:rounded-none;
}

/* Grade Sheet Popup */
.grade-input-form {
  @apply flex border-2 border-black shadow-md shadow-black rounded-xl bg-gray-700 z-40 absolute left-0 right-0 mx-auto bottom-14 w-5/6 h-52;
}

.positive-production-container {
  @apply absolute h-[65%] items-start top-16 ml-2 flex flex-wrap overflow-y-scroll scroll-smooth scrollbar-hide border-black bg-gray-600 w-1/3 shadow-inner shadow-gray-800 rounded-lg;
}

.pos-pro-dropdown-container {
  @apply scale-75 shadow-inner h-fit w-fit ml-2 -mx-1 last:mb-10 bg-gray-800 border-black shadow-gray-900 rounded-md p-1 text-center relative top-2 left-0 right-0 z-20;
}

.negative-production-container {
  @apply absolute h-[65%] items-start top-16 right-0 mr-2 flex flex-wrap overflow-y-scroll scroll-smooth scrollbar-hide bg-gray-600 w-1/3 shadow-inner shadow-gray-800 rounded-lg;
}

.drop-down-style {
  @apply text-black rounded-md bg-gray-200 border border-black shadow-inner shadow-gray-400 font-bold;
}

.editable-cell {
  position: relative;
}

.editable-cell-value-wrap {
  padding: 5px 12px;
  cursor: pointer;
  white-space: nowrap;
}

.editable-row:hover .editable-cell-value-wrap {
  padding: 5px 12px;
  outline: 1px solid #d9d9d9;
  border-radius: 2px;
  white-space: nowrap;
}

[data-theme="dark"] .editable-row:hover .editable-cell-value-wrap {
	outline: 1px solid #434343;
}

